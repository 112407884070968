export const MissingPageData = {
  SRB: {
    pageTitle: "Stranica nije pronađena",
    goBackLinkText: "Vratite se na početnu stranicu",
  },
  ENG: {
    pageTitle: "Page not found",
    goBackLinkText: "Go back to home page",
  },
};
