export const ClientsPageData = {
  SRB: {
    pageTitle: "Klijenti i reference",
    namesArray: [
      "Agrofutura Tech DOO, Novi Sad",
      "Wakler DOO, Novi Sad",
      "PK ProFarm, Novi Sad",
      "AG—Tech Consulting, Novi Sad",
      "B&G Consulting, Novi Sad",
      "Poljoprivredni fakultet, Novi Sad",
      "Istraživačko—obrazovni centar u poljoprivredi „Agro Campus”, Temerin",
      "Vranić Farm DOO, Kać",
      "Adrijana DOO, Novi Sad",
      "Veterinarska stanica Zrenjanin DOO, Zrenjanin",
      "Veterinarska stanica ZOVET DOO, Obrva (Kraljevo)",
      "Downtown Media Systems DOO, Zrenjanin",
      "Arborspalet 2010 DOO, Bačka Palanka",
      "Excentar DOO, Novi Sad",
      "Stilles DOO, Novi Sad",
      `Ing. „B—SD Gradnja” DOO, Novi Sad`,
      "OZZ „Begečki povrtari”, Begeč",
      "STR „Skalar”, Novi Sad",
    ],
  },
  ENG: {
    pageTitle: "Clients and references",
    namesArray: [
      "Agrofutura Tech DOO, Novi Sad",
      "Wakler DOO, Novi Sad",
      "PK ProFarm, Novi Sad",
      "AG—Tech Consulting, Novi Sad",
      "B&G Consulting, Novi Sad",
      "Faculty of Agriculture, Novi Sad",
      "Research & education center in agriculture „Agro Campus”, Temerin",
      "Vranić Farm DOO, Kać",
      "Adrijana DOO, Novi Sad",
      "Vet station Zrenjanin DOO, Zrenjanin",
      "Vet station ZOVET DOO, Obrva (Kraljevo)",
      "Downtown Media Systems DOO, Zrenjanin",
      "Arborspalet 2010 DOO, Bačka Palanka",
      "Excentar DOO, Novi Sad",
      "Stilles DOO, Novi Sad",
      `Ing. „B—SD Gradnja” DOO, Novi Sad`,
      "Farmers’ co—op „Begečki povrtari”, Begeč",
      "S.P. „Skalar”, Novi Sad",
    ],
  },
};
